import React from "react";
import "../styles/styles.css";

const CompanyIntro = () => {
  return (
    <div>
      {/* You can keep this div or remove it as well if you don't need it */}
    </div>
  );
};

export default CompanyIntro;
